import MessageAgentForm from "../Agents/MessageAgent/MessageAgentForm";
import MessageAgentModal from "../Agents/MessageAgent/MessageAgentModal";
import { useEffect, useState } from "react";
import { postEvent, resetSession } from "../Agents/MessageAgent/Analytics";
import { trackVirtualPageView } from "~/utils/Fetch/Analytics";
import { useRouter } from "next/router";
import FunnelCTA from "@referralexchange/rea-ui-react/src/components/blocks/Cta";
import Button from "@referralexchange/rea-ui-react/src/atoms/Button";
import styles from "./CTA.module.scss";

const INITIAL_STEP = 1;
const CTA = (props) => {
  const {
    fullScreen,
    funnel,
    title,
    buttonText,
    gradient = true,
    subtext,
    standalone = true,
  } = props;
  const router = useRouter();
  const [confirmClose, setConfirmClose] = useState(false);
  const [step, setStep] = useState(INITIAL_STEP);
  const [largeModal, setLargeModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      setStep(INITIAL_STEP);
    }
  }, [modalOpen]);

  const onRequestClose = (e, confirm = false) => {
    if (step === INITIAL_STEP) {
      setConfirmClose(false);
      postEvent("message_agent_modal_closed", router, null, funnel);
      resetSession();
      setModalOpen(false);
    } else if (confirm) {
      setConfirmClose(true);
      postEvent("message_agent_modal_exit_attempt", router, null, funnel);
    } else if (confirm === null) {
      setConfirmClose(false);
    } else {
      setModalOpen(false);
      postEvent("message_agent_modal_closed", router, null, funnel);
      resetSession();
      setTimeout(() => {
        setConfirmClose(false);
      }, 200);
    }
  };

  return (
    <>
      <div
        className={styles["CTA__pointer"]}
        onClick={() => {
          setModalOpen(true);
          trackVirtualPageView("funnel_start", funnel);
        }}
      >
        <FunnelCTA
          gradient={gradient}
          heading={title ? title : "Find Your Perfect Agent"}
          children={
            <Button children={buttonText ? buttonText : "Get Started"} />
          }
          fullScreen={fullScreen}
          subtext={subtext}
          standalone={standalone}
        />
      </div>
      <MessageAgentModal
        confirmClose={confirmClose}
        isOpen={modalOpen}
        largeModal={largeModal}
        onRequestClose={onRequestClose}
        agent={{}}
        funnel={funnel}
      >
        <MessageAgentForm
          agent={{ prime: true }}
          funnel={funnel}
          isClaimed={true}
          isOpen={modalOpen}
          afterNewScreen={setStep}
          step={step}
          onRequestClose={onRequestClose}
          onRequestLargeModal={setLargeModal}
        />
      </MessageAgentModal>
    </>
  );
};

export default CTA;
