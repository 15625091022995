import { css, StyleSheet } from "aphrodite";

import Section from "../components/Locations/Sections/Section";

import HomepageSection from "../components/Common/HomepageSection";
import WhyREA from "../components/WhyREA";
import ValuePropTwo from "../components/Common/ValuePropTwo";
import ValuePropCTA from "../components/Common/ValuePropCTA";
import Hero from "../components/Common/Hero";
import HomepageHero from "../components/Homepage/HomepageHero";
import Head from "~/components/Meta/Head";
import CtaSection from "../components/Locations/Sections/CtaSection";
import InputComponentCTA from "../components/Common/InputComponentCTA";
import CTA from "~/components/Common/CTA";

import { TESTIMONIAL_SEEDS, MAJOR_CITIES } from "../utils/Constants";
import { formatRealEstateAgentsLocationURL } from "../utils/AgentUtils";
import Link from "next/link";
import dynamic from "next/dynamic";
import Script from "next/script";
import useSessionStorage from "~/utils/useSessionStorage";
import { useState } from "react";
import Layout from "~/components/layouts/Home";
import Testimonials from "~/components/organisms/Testimonials";
import homepageStyle from "~/components/Homepage/Homepage.module.scss";
import { HOMEPAGE_FUNNEL } from "~/config/funnel";
import Blog from "@referralexchange/rea-ui-react/src/components/blocks/Blog";
import Tiles from "@referralexchange/rea-ui-react/src/components/blocks/Tiles";
import HouseIcon from "@referralexchange/rea-ui-react/src/svgs/features/house-with-heart";
import HouseWithPinIcon from "@referralexchange/rea-ui-react/src/svgs/features/house-with-pin";
import ForSaleSignIcon from "@referralexchange/rea-ui-react/src/svgs/features/for-sale-sign";
import { getPosts, formatBlogPosts } from "~/utils/Blog/Post";
import { blog as blogConfig } from "~/config/blog";

const Index = (props) => {
  const { blog_posts } = props;
  const showLogoutModal = useSessionStorage("show-logged-out-modal");

  const [step, setStep] = useState(1);
  const [selectedClientType, setSelectedClientType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const renderPopularCities = () => {
    return MAJOR_CITIES.map((location, index) => {
      const { url, path } = formatRealEstateAgentsLocationURL({
        city: location.city,
        state: location.state,
      });
      return (
        <Link
          prefetch={false}
          legacyBehavior
          as={url}
          href={path}
          key={`city_links_${index}`}
        >
          <a>
            <p className={css(styles.city)}>
              {location.city}, {location.state}
            </p>
          </a>
        </Link>
      );
    });
  };

  const searchOptions = {
    types: ["(regions)"],
    componentRestrictions: { country: ["us", "ca"] },
  };

  const renderLogoutModal = () => {
    if (showLogoutModal !== null) {
      const LogoutModal = dynamic(() =>
        import("~/components/Auth/LogoutModal")
      );
      return (
        <>
          <LogoutModal />
        </>
      );
    }
  };

  const homepageTiles = [
    {
      icon: HouseIcon,
      heading: "I'm Buying",
      content: "Find the best real estate agent to represent you",
      buttonText: "Explore agents",
      onClick: () => {
        setSelectedClientType("buying");
        setStep(2);
        setModalOpen(true);
      },
    },
    {
      icon: ForSaleSignIcon,
      heading: "I'm Selling",
      content: "A top REALTOR® will sell your home fast",
      buttonText: "Connect today",
      onClick: () => {
        setSelectedClientType("selling");
        setStep(2);
        setModalOpen(true);
      },
    },
    {
      icon: HouseWithPinIcon,
      heading: "I'm Buying & Selling",
      content:
        "The top rated real estate agent can support your entire journey",
      buttonText: "Get started",
      onClick: () => {
        setSelectedClientType("buying and selling");
        setStep(2);
        setModalOpen(true);
      },
    },
  ];

  return (
    <>
      <div className={css(styles.locationPage)}>
        <Head
          title={"Find Your Perfect Real Estate Agent | RealEstateAgents.com"}
          url={`${process.env.CLIENT_ORIGIN}`}
          description={
            "RealEstateAgents.com is the best real estate agent recommendation service in the U.S. We've spent more than a decade observing the very best real estate agents in every market."
          }
          keywords={
            "sell my house, find a real estate agent, find a listing agent, list my home for sale, sell your house fast, sell my house fast, top real estate agents"
          }
        />
        <Hero
          content={
            <HomepageHero
              step={step}
              setStep={setStep}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              selectedClientType={selectedClientType}
            />
          }
          contentWrapperStyles={[styles.homepageHero]}
          backgroundSrc={"/static/homepage_hero.webp"}
        />
        <Section
          title={null}
          content={<Tiles tiles={homepageTiles} />}
          containerStyles={styles.tileSection}
        />
        <Section
          title={`The Fastest Way to Compare the Best REALTORS®`}
          content={<WhyREA />}
          titleStyles={styles.title}
        />
        <Section
          title={"What our clients are saying"}
          content={
            <Testimonials
              className={homepageStyle["Homepage__testimonials"]}
              isPersonalRelocationNetwork={true}
              items={[
                {
                  image: null,
                  company: "Connecticut",
                  circleColor: "green",
                  name: "Heather S.",
                  stars: 5.0,
                  date: "08/23/23",
                  link:
                    "https://www.shopperapproved.com/reviews/34366?reviewid=166924386&social=1",
                  content:
                    '"Easy to use and the recommendations were fantastic! I loved how you could easily compare the experience of the realtors and how quickly everyone reached out to me."',
                  readMore: "#",
                },
                {
                  image: null,
                  company: "Tennessee",
                  circleColor: "blue",
                  name: "Ronda R.",
                  stars: 5.0,
                  date: "08/31/23",
                  link:
                    "https://www.shopperapproved.com/reviews/34366?reviewid=167438214&social=1",
                  content: `"It was easy, found a recommended realtor before I moved and he was great through the whole process."`,
                  readMore: "#",
                },
                {
                  image: null,
                  company: "Florida",
                  circleColor: "eggshell",
                  name: "Dave ",
                  stars: 5.0,
                  date: "10/21/23",
                  link:
                    "https://www.shopperapproved.com/reviews/34366?reviewid=170147776&social=1",
                  content: `"We couldn't be happier with our experience."`,
                  readMore: "#",
                },
              ]}
            />
          }
          titleStyles={styles.title}
        />
        <Section
          content={
            <ValuePropCTA
              title="The best local real estate agents"
              subtext=""
              bulletPoints={[
                "Receive personal agent recommendations",
                "Compare agent profiles for the best fit",
                "No commitment to work with an agent",
              ]}
              image="/static/sell_cta.webp"
              altText="Couple sells home with confidence"
              testimonial={TESTIMONIAL_SEEDS[6]}
              placeholder="What city are you selling in?"
              searchOptions={searchOptions}
            />
          }
          containerStyles={styles.sellContainer}
        />
        <Section
          content={
            <ValuePropCTA
              title="A top agent partner for homebuyers"
              subtext=""
              bulletPoints={[
                "An agent guides your buying process",
                "Identify the top agent for your needs",
                "Connect with top performers instantly",
              ]}
              image="/static/buy_cta.webp"
              altText="Couple buying home for the right price"
              type={"buy"}
              testimonial={TESTIMONIAL_SEEDS[1]}
              placeholder="What city are you buying in?"
              searchOptions={searchOptions}
            />
          }
        />
        <Section
          content={
            <CTA
              title={"Get matched with top real estate agents in your area"}
              funnel={HOMEPAGE_FUNNEL}
              buttonText={"Find Your Agent"}
            />
          }
        />
        <HomepageSection
          title={"Search for top agents by popular cities"}
          content={
            <div className={css(styles.cityLinks)}>{renderPopularCities()}</div>
          }
        />
        {blog_posts && blog_posts.length > 0 && (
          <Section
            content={
              <Blog
                title={"More Resources"}
                posts={blog_posts}
                button={{
                  label: "View More",
                  href: blogConfig.baseUrl,
                }}
              />
            }
          />
        )}
        <CtaSection
          title={`Find the perfect agent for you`}
          description={
            "See your personalized list of agents. 100% free service."
          }
          action={
            <div className={css(styles.ctaInputContainer)}>
              <InputComponentCTA
                inputPlaceholder={"Where are you buying or selling?"}
                options={searchOptions}
                inputGroupStyle={[styles.inputGroup]}
                inputContainerStyle={[styles.inputContainer]}
              />
            </div>
          }
        />
        {renderLogoutModal()}
      </div>
      <Script strategy="beforeInteractive" type="application/ld+json">
        {{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "RealEstateAgents.com",
          url: "https://www.realestateagents.com/",
          parentOrganization: "ReferralExchange, Inc.",
          sameAs: [
            "https://www.facebook.com/realestateagentsdotcom",
            "https://www.instagram.com/realestateagentsdotcom",
          ],
          description:
            "The mission of RealEstateAgents.com is to make sure you actually enjoy the process of buying and selling your home. Continuous innovation keeps us at the forefront of next-gen real estate tech.",
          address: {
            "@type": "PostalAddress",
            streetAddress: "425 Bush St. Ste 200",
            addressLocality: "San Francisco",
            addressRegion: "CA",
            postalCode: "94105",
            addressCountry: "US",
          },
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "+1(888)586-2349",
            email: "support@realestateagents.com",
            contactType: "customer service",
          },
        }}
      </Script>
    </>
  );
};

const styles = StyleSheet.create({
  altBackground: {
    background: "rgba(252, 252, 251, 1)",
    paddingTop: 100,
    paddingBottom: 80,
    marginTop: 50,
    marginBottom: 50,

    "@media only screen and (max-width: 767px)": {
      paddingTop: 1,
      paddingBottom: 1,
    },
  },
  altSection: {
    margin: 0,
  },
  cityLinks: {
    gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
    display: "grid",
    columnGap: 80,

    "@media only screen and (max-width: 1439px)": {
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      columnGap: 50,
    },

    "@media only screen and (max-width: 1023px)": {
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      columnGap: 50,
    },

    "@media only screen and (max-width: 767px)": {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      columnGap: 50,
    },
  },
  city: {
    fontSize: 16,
    fontWeight: 600,
    height: 50,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    ":hover": {
      opacity: 0.6,
    },
  },
  title: {
    "@media only screen and (max-width: 767px)": {
      fontSize: 28,
      lineHeight: "34px",
      fontWeight: 600,
    },
  },
  inputContainer: {
    height: 60,
    padding: 0,
    borderRadius: 5,
    width: "100%",

    "@media only screen and (max-width: 1023px)": {
      width: "100%",
    },
  },
  inputGroup: {
    background: "rgba(248, 248, 248, 1)",
    height: "100%",
    width: "calc(100% - 50px)",
    boxSizing: "border-box",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,

    "@media only screen and (max-width: 1300px)": {
      width: "100%",
      borderRadius: 5,
    },
  },
  ctaInputContainer: {
    width: 530,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  homepageHero: {
    zIndex: 3,
  },
  sellContainer: {
    zIndex: 3,
  },
  tileSection: {
    marginTop: 100,
    marginBottom: 100,
  },
});

Index.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

export const getStaticProps = async () => {
  const filters = "tag:[buying, mortgage, process, selling]";
  const [posts_res] = await Promise.all([getPosts({ filters, pageLimit: 6 })]);

  const [ghost] = await Promise.all([posts_res.data]);
  const blog_posts = formatBlogPosts(ghost.posts);
  return { props: { blog_posts }, revalidate: 60 * 5 };
};

export default Index;
